import create from 'zustand'


export type AppDataType = {
    [key: string]: string | string[] | number | boolean
}

export interface AppDataStore {
  appData: AppDataType
  setAppData: (appData: AppDataType) => void
}

export const useAppDataStore = create<AppDataStore>(set => ({
  appData: {
  },

  setAppData: (appData: AppDataType) => set(state => ({ ...state, appData })),

}))
