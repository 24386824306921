
interface Props {
  title: string;
  value: number | string;
  notdisplayed: boolean;
  optionsArray: any;
  disabledArray: number[];
  optionsByDependency: number[];
  handleChange: (value: any) => void;
  disabled?: boolean;
}

const LabeledSelect = (props: Props) => {

  console.log('props:', props)

  const options = props.optionsArray.filter((obj: any) => props.optionsByDependency.includes(obj.id) )

  return (
    <div>
      <label className={(props.optionsByDependency.length === 0 || props.notdisplayed) ? 'red': 'green'}>{props.title}: </label>
      <select name={props.title} id={props.title} disabled={props.disabled} onChange={e => props.handleChange(e.target.value)} value={props.value}>
        {options.map((opt: any, index: number) => <option key={opt.textId+index} value={opt.id} disabled={props.disabledArray.includes(opt.id)} >{opt.textId}</option> )}

        
      </select>
    </div>
  );
};

export default LabeledSelect;
