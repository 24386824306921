import React, {useState, useEffect} from 'react'
import isEmpty from '../Utils/isEmpty'

import { useAppDataStore } from '../store'

import base from '../Assets/BasisListen.json'

import LabeledSelect2 from '../Components/LabeledSelect2'
import NumericInput from '../Components/NumericInput'

export type TabObjectDataType = {
    [key: string]: any
}

interface Props{
    elemets: string;
    trigger: boolean
}

export default function TabByJSON(props: Props): JSX.Element {

    const dataProps = useAppDataStore(state => state.appData);
    const setDataProps = useAppDataStore(state => state.setAppData);

    const baseCopy: TabObjectDataType = Object.assign(base);

    const [elemets, setElements] = useState<TabObjectDataType>({})
    const [keyList, setKeyList] = useState<string[]>([])

    const [error, setError] = useState<string>('')
    
    // const [isMounted, setIsMounted] = useState<boolean>(false)

    useEffect( () => {

        try {
            
            setElements(JSON.parse(props.elemets))
            setError('')
        } catch (error: any) {

            console.log('error:', error)
            setError(JSON.stringify(error, Object.getOwnPropertyNames(error)))
        }


    }, [props.trigger])

    useEffect( () => {

        Object.keys(elemets).length > 0 ? setKeyList(Object.keys(elemets).filter((objStr: string) =>  !isEmpty( elemets[objStr] ) )) : setKeyList([])

    }, [elemets])


    useEffect( () => {

        if(!isEmpty(keyList)){
            let tempObj: TabObjectDataType = {defaultValue: "-1"};

            keyList.forEach((str: string) => {
                Object.assign(tempObj, {[str]: '-1'})
            })

            keyList.forEach((str: string) => {
                if(str !== "defaultValue"){

                    const val = initialValue(str, tempObj);

                    console.log(str,'val:', val)
                    
                    tempObj[str] = val.initial.toString();
                }
            })
            setDataProps(tempObj);
            // keyList.length > 1 ? setIsMounted(true) : setIsMounted(false)
        }
        // else if (isEmpty(keyList)){

            
        //     setIsMounted(false)
        // }

    },[keyList])

    const initialValue = (objStr: string, tempObj: TabObjectDataType) => {

        const dat = elemets[objStr].filter((obj: any) => getInitialValue(obj, tempObj) !== null )
        console.log(objStr,'dat:', dat)
        return dat.length > 0  ? dat[0].Value : {"id": objStr, "initial": "-1", "auswirkungAuf": []};
        // return dat.length > 0  ? dat[0].Value : elemets[objStr][0].Value ;
        // return (dat.length > 0 && (dat[0].Value.typ === 1 || dat[0].Value.typ === 2)) ? dat[0].Value : {"id_Initial": "", "auswirkungAuf": []};
    }

    const checkDependencies = (obj: any, list: string[], tempObj: TabObjectDataType) => {

        let isTrue = false;

        for (var i = 0; i < list.length; i++) {
                        
            if( obj.Key[list[i]].toString() !== tempObj[list[i]] ){
                isTrue = false
                break
            }else{
                isTrue = true
            }
            
        }

        return isTrue ? obj : null
    }

    const getInitialValue = (obj: any, tempObj: TabObjectDataType) => {
        
        const kList = Object.keys(obj.Key)
        return checkDependencies(obj, kList, tempObj) !== null ? obj.Value : null;
    }

    function handleSelectChange(idStr: string, value: string, arg: any){

        dataProps[idStr] = value;

        // console.log('arg:', arg)

        arg.auswirkungAuf.forEach((str: string) => {

            const val = initialValue(str, dataProps);
            handleSelectChange(str, val.initial.toString(), val)
            // dataProps[str] = val.toString();

        })

        setDataProps({...dataProps})

    }

    function handleElement(idStr:string, arg: any) {

        if(typeof arg === 'object'){

            switch(arg.typ){

                case 1:{
                    return <div key={idStr}>
                                <LabeledSelect2 
                                    title = {idStr}
                                    value = {dataProps[idStr].toString()}
                                    notdisplayed = {arg.ausgeblendet}
                                    optionsArray = {baseCopy[idStr]}
                                    disabledArray = {arg.grauGesperrt_Ids}
                                    optionsByDependency = {arg.eintraege_Ids}
                                    handleChange = { (value: string) => handleSelectChange(idStr, value, arg) } 
                                    disabled = {arg.grau_gesperrt}
                                />
                            </div>
                }
                case 3:{
                    return <div key={idStr}><NumericInput
                        title = {idStr}
                        value = {dataProps[idStr].toString()}
                        notdisplayed = {arg.ausgeblendet}
                        min = {arg.grenzeUnten.toString()}
                        max = {arg.grenzeOben.toString()}
                        disabled = {arg.grau_gesperrt}
                        onChange = { (value: any) => setDataProps({...dataProps, [idStr]: value}) }
                    /></div>
                }
            }
        } 

        return <span key={idStr} className={'red'} >{idStr+': '+arg}<br/></span>;
    }

    const getData = (obj: any) => {
        
        const kList = Object.keys(obj.Key)
        return checkDependencies(obj, kList, dataProps) !== null ? obj.Value : null;
    }

    return(
        <div style={{'width': '100%'}}>

            {(isEmpty(error) ) && <div className="output-area-container"> 
                <div className='output-area-container-left'>
                    <br/>
                    {keyList.map((objStr: string) => {

                            const data = elemets[objStr].filter((obj: any) => getData(obj) !== null )
                            return handleElement(objStr, data.length === 0 ? "no dependency fulfilled" : data[0].Value)

                    })}
                </div>
                <br/>
                <br/>
                {/* <div style={{width: '95%'}}> */}
                <div className='output-area-container-right'>
                    <b>store:</b><br/>
                    {Object.keys(dataProps).map((key: string) => <span key={key}> {key+': '+dataProps[key]}< br/></span>)}
                </div>


            </div>}
            <br/>
            <br/>
            {!isEmpty(error) && <span>{error}</span>}

        </div> 
    )
}   