import React, { ReactNode, useEffect } from 'react'

type Props = {
    title: string;
    value: string;
    notdisplayed: boolean
    min?: string
    max?: string
    disabled?: boolean
    onChange: (value: any) => void
}

export default function NumericInput(props: Props): JSX.Element {

  return (
    <div>
      <label className={props.notdisplayed ? 'red': 'green'}>{props.title}</label>
      <input type="text" onChange={e => props.onChange(e.target.value)} value={props.value}></input>
      {props.min && <span style={{lineHeight:"0.75em", fontSize: "0.75em"}}><sup> min: {props.min} </sup></span> }
      {props.max && <span style={{lineHeight:"0.75em", fontSize: "0.75em"}}><sup>max: {props.max} </sup></span>}
    </div>      
  )
}